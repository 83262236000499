@import "../styles/colours";

$top-difference: 200px;

.pp-footer {
  background-color: $green-dark;
  height: 408px;
  padding: 65px 0 80px;
  position: relative;

  .footer-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .holders-image {
      width: 100%;
      height: calc(100% + $top-difference);
      background-repeat: no-repeat;
      background-position: left top;
      background-size: contain;
      margin-top: -$top-difference;
    }
  }

  .copyright {
    font-size: 13px;
    color: $grey-mid;
    background-color: $black;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;

    .col {
      display: flex;
      height: 82px;
      align-items: center;
    }
  }
}