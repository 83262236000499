@import "../styles/colours";
@import "bootstrap/scss/mixins";
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.pp-text-image {
  position: relative;

  @include media-breakpoint-down(md) {
    padding-bottom: 20px;
  }

  .bg-overflow {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;

    @include media-breakpoint-down(lg) {
      height: 100% !important;
      top: 0 !important;
    }
  }

  &.green{
    .bg-overflow {
      background-color: $green-dark;
    }

    .title {
      color: white;
    }

    .body {
      color: $grey-mid;
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .pp-text-image-row {
    &.direction-reverse {
      flex-direction: row-reverse;

      .col-12 {
        display: flex;
        justify-content: flex-end;
      }
      
      @include media-breakpoint-down(md) {
        flex-direction: row !important;
      }
    }

    .text-col {
      max-width: 380px;

      .highlight {
        font-size: 16px;
        margin-bottom: 20px;
        font-family: "DINBold";
      }

      .title {
        font-size: 50px;
        margin-bottom: 20px;
        font-family: "DINBold";
      }

      .body {
        font-size: 16px;
        max-width: 460px;

        @include media-breakpoint-down(md) {
          font-size: 23px;
        }
      }
    }
  }
}