@import "../styles/colours";
@import "bootstrap/scss/mixins";
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.pp-cta-with-arrow {
  padding: 50px 0;

  .row {

    .text-col {
      min-width: 560px;

      @include media-breakpoint-down(md) {
        min-width: unset;
      }

      .highlight {
        font-family: "DINBold";
        margin-bottom: 10px;
      }

      .heading {
        h2 {
          font-size: 50px;
          margin-bottom: 10px;
        }
      }

      .copy {
        color: $grey-mid-dark;
      }
    }

    .arrow-col {
      text-align: center;
      flex: 1;

      @include media-breakpoint-down(xl) {
        display: none;
      }
    }

    .cta-col {
      max-width: 200px;

      @include media-breakpoint-down(xl) {
        margin-top: 25px;
      }
    }
  }

}