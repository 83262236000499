@import "bootstrap/scss/mixins";
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.pp-title {
  padding: 100px 0;
  display: flex;
  align-items: center;

  .title-text {
    font-size: 60px;
    max-width: 1017px;

    @include media-breakpoint-down(md) {
      font-size: 57px;
    }

    br {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }
}