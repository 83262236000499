@import "../styles/colours";
@import "../styles/mixins";
@import "bootstrap/scss/mixins";
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.pp-steps {
  padding: 50px 0;

  .step-col {
    @include media-breakpoint-down(md) {
      margin-bottom: 50px;
    }

    .image {
      height: 350px;
      @include bgDefault(cover);
    }

    .step-no {
      margin-top: 25px;
      font-size: 16px;
      font-family: "DINBold";
      color: $grey-mid-dark;
    }

    .copy {
      margin-top: 15px;
      font-size: 16px;
      font-family: "DINBold";
      padding-right: 50px;

      @include media-breakpoint-down(md) {
        padding-right: 0;
      }
    }
  }
}