@mixin isRetina {
  @media (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
    @content
  }
}

@mixin bgDefault($size) {
  background-repeat: no-repeat;
  background-position: center;
  background-size: $size;
}