@import "../styles/colours";
@import '~bootstrap/scss/mixins/_breakpoints';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.pp-quotes {
  padding: 55px 0 80px 0;
  background-color: white;

  .title-col {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;

    h2 {
      font-size: 22px;
    }
  }

  .quote-col {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(md) {
      margin-bottom: 25px;
    }

    .text {
      margin-left: 20px;

      .name {
        font-size: 14px;
        font-family: "DINBold";
      }

      .quote {
        font-size: 20px;
        padding-right: 50px;

        @include media-breakpoint-down(lg) {
          padding-right: 0;
        }
      }
    }
  }
}