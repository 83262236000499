@import "../styles/mixins";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.pp-center-image-two-col {
  padding: 100px 0;

  .row {
    height: 400px;
    @include bgDefault(contain);
    position: relative;

    @include media-breakpoint-down(lg) {
      justify-content: space-between;
      background-image: none !important;
    }

    &:before {
      position: absolute;
      width: 100%;
      height: 97%;
      top: -18%;
      left: -4%;
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='621.56' height='375.72' viewBox='0 0 621.56 375.72'%3E%3Cg id='Group_17' data-name='Group 17' transform='translate(-320.072 -1985.603)'%3E%3Cpath id='Path_5' data-name='Path 5' d='M2200,2223.775s-.577,136.548,224.522,136.548' transform='translate(-1878.928)' fill='none' stroke='%232a392f' stroke-width='2' opacity='0.23'/%3E%3Cpath id='Path_6' data-name='Path 6' d='M0,120.642S-.413,0,160.633,0' transform='translate(872.839 1986.932) rotate(65)' fill='none' stroke='%232a392f' stroke-width='2' opacity='0.23'/%3E%3C/g%3E%3C/svg%3E%0A");
      @include bgDefault(contain);

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    .text-col {
      max-width: 290px;

      @include media-breakpoint-down(lg) {
        max-width: 100%;
        display: flex;
        justify-content: center;
        background-color: rgba(255, 255, 255, 1);
        flex-direction: column;
        border-radius: 10px;
        margin-bottom: 20px;
      }

      h3 {
        font-size: 29px;
        font-family: "DINBold";
      }

      p {
        font-size: 14px;
      }

      &.left {
        margin-top: 35px;

        @include media-breakpoint-down(lg) {
          margin-top: 0;
        }
      }

      &.right {
        margin-top: 100px;

        @include media-breakpoint-down(lg) {
          margin-top: 0;
        }
      }

      &.spacer {
        max-width: 37%;

        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
    }

  }
}