@import "bootstrap/scss/mixins";
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.header-container {
  padding-top: 50px;
  color: white;
  height: 85px;
  z-index: 2;
  position: relative;

  .header-row {
    z-index: 2;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;

    .pp-logo {
      width: 25px;
    }

    .pp-logo-text {
      margin-left: 15px;
      font-size: 25px;
      color: white;
      margin-bottom: -4px;
    }

    .why-penpal {
      font-size: 13px;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}