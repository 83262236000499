.pp-title-with-cta {
  padding: 100px 0;


  .title-col {
    max-width: 410px;
    text-align: center;
    margin-bottom: 25px;

    h2 {
      font-size: 41px;
    }
  }

  .cta-col {
    display: flex;
    justify-content: center;
  }
}