.pp-video {
  width: 100%;
  height: 100%;

  .iframe-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .video-iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
}