@font-face {
    font-family: 'DIN';
    src: url('./fonts/DINNextLTPro-Regular.woff2') format('woff2');
    src: url('./fonts/DINNextLTPro-Regular.woff') format('woff');
    src: url('./fonts/DINNextLTPro-Regular.ttf')  format('truetype');
}

@font-face {
    font-family: 'DINBold';
    src: url('./fonts/DINNextLTPro-Bold.woff2') format('woff2');
    src: url('./fonts/DINNextLTPro-Bold.woff') format('woff');
    src: url('./fonts/DINNextLTPro-Bold.ttf')  format('truetype');
}