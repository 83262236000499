@import "../styles/colours";
@import "bootstrap/scss/mixins";
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';


.pp-get-started {
  .container {
    background-color: #fff;
    border-radius: 7px;
    padding: 50px 0;
    -webkit-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.1);
    display: flex;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    .get-started-col {
      display: flex;
      flex-direction: column;
      width: 50%;
      align-items: center;

      @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 50px;
      }

      &.basic {
        border-right: 1px solid rgba(0, 0,0, 0.15);

        @include media-breakpoint-down(md) {
          padding-bottom: 50px;
          border-right: none;
          border-bottom: 1px solid rgba(0,0,0, 0.15);
        }
      }

      .title {
        .highlight {
          color: $yellow;
          font-family: "DINBold";
          font-size: 20px;
          min-height: 30px;
        }

        .title {
          font-size: 50px;
          font-family: "DINBold";
        }
      }

      .image {
        margin-bottom: 20px;
      }

      .pricing{
        .desc {
          font-size: 20px;
          font-family: "DINBold";
        }

        .price {
          font-size: 70px;
          font-family: "DINBold";
          position: relative;

          .crossed {
            position: absolute;
            text-decoration: line-through;
            font-size: 30px;
            color: $grey-light;
          }
        }
      }

      .cta {
        .get-started-cta {
          max-width: 173px;
          font-size: 23px;
        }
      }

      .details {
        margin-top: 70px;

        .detail {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          .icon {
            opacity: 0.6;
            min-width: 20px;
            text-align: center;
          }

          .text {
            padding-left: 10px;
            font-size: 18px;
            line-height: 1.5;
            color: rgba(0,0,0, 0.6)
          }
        }
      }

    }
  }
}