@import "../styles/mixins";
@import "../styles/colours";
@import '~bootstrap/scss/mixins/_breakpoints';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';


.pp-hero {
  margin-top: -85px;
  padding: 85px 0;
  min-height: 100vh;
  //background-color: #2A392F;
  @include bgDefault(cover);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='1367' height='706' viewBox='0 0 1367 706'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.831' y1='0.048' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%232a392f'/%3E%3Cstop offset='1' stop-color='%23171715'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect id='Rectangle_2' data-name='Rectangle 2' width='1367' height='706' fill='url(%23linear-gradient)'/%3E%3C/svg%3E%0A");
  position: relative;

  @include media-breakpoint-down(md) {
    min-height: 600px;
  }

  .container {
    @include media-breakpoint-down(md) {
      position: relative;
    }
  }

  .bg-image {
    @include bgDefault(contain);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .text-col {
    color: white;

    @include media-breakpoint-down(md) {
      margin-top: 50px;
    }

    .hero-text {
      font-size: 70px;
      line-height: 0.7;
    }

    .hero-desc {
      font-size: 20px;
    }

    .buttons {
      margin-top: 50px;

      @include media-breakpoint-down(md) {
        margin-top: 65%;
      }

      h3 {
        font-size: 20px;
        color: $grey-mid;
        max-width: 200px;
        margin-bottom: 10px;
      }

      .buttons-wrapper {
        .watch-video {
          margin-left: 40px;
        }
      }
    }
  }
}