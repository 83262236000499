@import "./colours";
@import "./mixins";

.pp-app {
  font-family: "DIN";
  background: $grey-light;
  color: $black;

  .container {
    @include media-breakpoint-down(md) {
      padding-left: 30px;
      padding-right: 30px;
    }

    @include media-breakpoint-up(xl) {
      max-width: 1200px;
    }
  }

  &.noscroll {
    overflow-y: hidden;
  }

  h1, h2 {
    font-family: "DINBold";
    line-height: 1;
  }

  button {
    background-color: unset;
    border: none;
    padding: 0;

    &:focus, &:active {
      outline: none;
      border: none;
      background-color: inherit;
      box-shadow: inherit !important;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;

    &:focus, &:active {
      outline: none;
      border: none;
      background-color: inherit;
    }
  }

  .pp-btn {
    border: none;
    font-size: 13px;
    cursor: pointer;

    &.watch-video {
      padding-left: 10px;
      position: relative;
      font-family: "DINBold";
      font-size: 16px;

      &:hover {
        background-color: transparent;
      }

      &:before {
        position: absolute;
        content: '';
        width: 20px;
        height: 20px;
        left: -20px;
        @include bgDefault(contain);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'%3E%3Cg id='Group_2' data-name='Group 2' transform='translate(-963 -488)'%3E%3Cg id='Ellipse_10' data-name='Ellipse 10' transform='translate(963 488)' fill='none' stroke='%23fff' stroke-width='2'%3E%3Ccircle cx='11' cy='11' r='11' stroke='none'/%3E%3Ccircle cx='11' cy='11' r='10' fill='none'/%3E%3C/g%3E%3Cpath id='Polygon_2' data-name='Polygon 2' d='M5,0l5,9H0Z' transform='translate(979.5 494) rotate(90)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
      }
    }

    &.sign-up {
      background-color: $yellow;
      font-family: "DINBold";
      color: black;
      font-size: 16px;
      position: relative;

      &.small {
        width: 95px;
        height: 36px;
      }

      &.mid {
        width: 173px;
        height: 62px;
        font-size: 23px;

        @include media-breakpoint-down(md) {
          height: 45px;
          line-height: 1;
        }
      }
    }

    &.btn-video-cta {
      font-size: 16px;
      font-family: "DINBold";
      position: relative;
      padding-left: 30px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 22px;
        height: 22px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'%3E%3Cg id='play' transform='translate(-621 -3306)'%3E%3Cg id='Ellipse_18' data-name='Ellipse 18' transform='translate(621 3306)' fill='none' stroke='%23000' stroke-width='2'%3E%3Ccircle cx='11' cy='11' r='11' stroke='none'/%3E%3Ccircle cx='11' cy='11' r='10' fill='none'/%3E%3C/g%3E%3Cpath id='Polygon_3' data-name='Polygon 3' d='M5,0l5,9H0Z' transform='translate(637.5 3312) rotate(90)'/%3E%3C/g%3E%3C/svg%3E%0A");
        @include bgDefault(contain);
      }
    }

    &.btn-cta-green {
      background-color: $green-dark;
      color: white;
      border-radius: 12px;
      padding: 15px 35px;
      font-size: 16px;
      font-family: "DINBold";
      text-align: center;
    }

    &.btn-cta-yellow {
      background-color: $yellow;
      color: white;
      border-radius: 12px;
      padding: 15px 35px;
      font-size: 16px;
      font-family: "DINBold";
      text-align: center;
    }
  }

  .pp-image {
    display: block;
    max-width: 100%;
  }

  .color-grey {
    color: $grey-mid-light;
  }
}