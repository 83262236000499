.pp-faq {
  .title-row {
    margin-bottom: 30px;

    h2 {
      font-size: 41px;
    }
  }

  .accordion-row {
    max-width: 630px;

    .col {
      padding-bottom: 30px;

      .pp-accordion-header {
        padding-bottom: 10px;
        margin-bottom: 20px;
        font-size: 20px;
        border-bottom: 1px solid #BEBEBE;
        cursor: pointer;
      }
    }
  }
}