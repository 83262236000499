@import "bootstrap/scss/mixins";
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';


.pp-modal {
  position: fixed;
  background-color: transparent;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  padding: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0000005C 0% 0% no-repeat padding-box;

  @include media-breakpoint-down(md) {
    padding: 15px;
  }


  .pp-modal-body {
    width: 100%;
    height: 100%;
    background-color: white;
    box-shadow: 5px 10px 30px #0000000F;
    border: 3px solid #F0F0F0;
    border-radius: 28px;
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 1143px;
    position: relative;

    @include media-breakpoint-down(md) {
      padding: 100px 15px;
    }

    &.video {
      padding: 0;
      background-color: black;
      border-radius: 0;
      border: none;
      min-height: 400px;
    }

    .modal-close {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      position: absolute;
      top: 0;
      left: 0;
      padding-top: 50px;
      padding-right: 50px;

      @include media-breakpoint-down(md) {
        padding-top: 25px;
        padding-right: 25px;
      }

      .close-button {
        width: 50px;
        height: 50px;
        cursor: pointer;
      }
    }

    .pp-modal-content {
      max-width: 750px;

      @include media-breakpoint-down(md) {
        width: 100%;
      }

      .text {
        font-size: 48px;
        font-family: "DINBold";
        line-height: 1;
        margin-bottom: 90px;

        @include media-breakpoint-down(lg) {
          font-size: 36px;
          margin-bottom: 20px;
        }

        @include media-breakpoint-down(sm) {
          font-size: 22px;
          line-height: 30px;
        }
      }

      .form {
        display: flex;
        width: 100%;

        @include media-breakpoint-down(md) {
          flex-direction: column;
        }

        input[type="email"] {
          flex: 1;
          margin-right: 25px;
          border: 3px solid #BEBEBE;
          border-radius: 6px;

          @include media-breakpoint-down(md) {
            line-height: 40px;
            margin-bottom: 20px;
          }
        }

      }

      .warning {
        color:red;
        margin-top: 10px;
      }
    }
  }
}