@import "../styles/mixins";
@import "../styles/colours";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.pp-text-on-image {
  .container {
    height: 507px;
    @include bgDefault(cover);
    padding: 0;

    .text-col {
      width: 50%;
      background: $grey-light;
      background: linear-gradient(90deg, rgba(242,242,242,1) 50%, rgba(242,242,242,0) 100%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $green-dark;

      @include media-breakpoint-down(lg) {
        width: 100%;
        padding: 30px;
        background: rgba(242,242,242,0.5)
      }

      .tagline  {
        font-size: 16px;
        font-family: "DINBold";
        margin-bottom: 25px;
      }

      .title {
        font-size: 50px;
        font-family: "DINBold";
        margin-bottom: 25px;
        max-width: 420px;
        line-height: 1;
      }

      .copy {
        font-size: 16px;
        max-width: 380px;
        opacity: 0.4;

        @include media-breakpoint-down(lg) {
          opacity: 1;
        }
      }
    }
  }
}