@import "../styles/colours";
@import "../styles/mixins";
@import "bootstrap/scss/mixins";
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.pp-why-penpal {
  padding: 50px 0;

  .container {
    position: relative;

    .row {
      display: flex;
      justify-content: center;
    }

    .overflow-image {
      width: 360px;
      height: 450px;
      position: absolute;
      left: 35px;
      top: -173px;
      @include bgDefault(cover);

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }

  .title-row {
    h2 {
      font-size: 25px;
      color: $green-dark;
    }
  }

  .vs-row {
    margin-top: 40px;
    margin-bottom: 35px;

    .text-col {
      font-size: 37px;
      font-family: "DINBold";
      max-width: 33%;

      @include media-breakpoint-down(md) {
        font-size: 25px;
      }

      &.left {
        text-align: right;
      }

      &.right {
        text-align: left;
      }
    }

    .separator {
      text-align: center;
      max-width: 50px;
      margin: 0 80px;

      @include media-breakpoint-down(md) {
        margin: 0 10px;
      }
    }
  }

  .box-title-row {
    text-align: center;

    h2 {
      font-size: 30px;
      font-family: "DIN";
      color: $grey-mid-dark;
      margin-bottom: 15px;
    }
  }

  .boxes-row {
    margin-bottom: 55px;

    .box {
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      max-width: 500px;
      margin: 0 10px 10px 10px;
      text-align: center;

      @include media-breakpoint-down(lg) {
        max-width: 44%;
      }
    }
  }

}